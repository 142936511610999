/* Updated App.css */

@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400;700&family=Inter:wght@400;600&display=swap');

:root {
  --writings: #9a031e;
  --nav: #9a031e;
  --email: #6d0210;
  --hover: #c21833;
}
/* Global styles */
body {
  font-family: 'Domine', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20version='1.1'%20xmlnsXlink='http://www.w3.org/1999/xlink'%20viewBox='0%200%20700%20700'%20width='700'%20height='700'%3E%3Cdefs%3E%3Cfilter%20id='nnnoise-filter'%20x='-20%25'%20y='-20%25'%20width='140%25'%20height='140%25'%20filterUnits='objectBoundingBox'%20primitiveUnits='userSpaceOnUse'%20color-interpolation-filters='linearRGB'%3E%3CfeTurbulence%20type='turbulence'%20baseFrequency='0.417'%20numOctaves='4'%20seed='15'%20stitchTiles='stitch'%20x='0%25'%20y='0%25'%20width='100%25'%20height='100%25'%20result='turbulence'%20/%3E%3CfeSpecularLighting%20surfaceScale='13'%20specularConstant='0.6'%20specularExponent='20'%20lighting-color='rgba(144,19,254,0.7)'%20x='0%25'%20y='0%25'%20width='100%25'%20height='100%25'%20in='turbulence'%20result='specularLighting'%3E%3CfeDistantLight%20azimuth='3'%20elevation='85'%20/%3E%3C/feSpecularLighting%3E%3CfeColorMatrix%20type='saturate'%20values='0'%20x='0%25'%20y='0%25'%20width='100%25'%20height='100%25'%20in='specularLighting'%20result='colormatrix'%20/%3E%3C/filter%3E%3C/defs%3E%3Crect%20width='700'%20height='700'%20fill='transparent'%20/%3E%3Crect%20width='700'%20height='700'%20fill='%239013fe'%20filter='url(%23nnnoise-filter)'%20/%3E%3C/svg%3E");
  background-repeat: repeat;
}

.app {
  margin: 0 auto;
  padding: 4rem 2rem;
  max-width: 600px;
}

.header {
  text-align: left;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
}

.nav-links a {
  text-decoration: none;
  color: var(--nav);
  font-size: 0.9rem;
}

.nav-links a:hover {
  color: var(--hover);
  text-decoration: underline;
}

.intro {
  text-align: left;
  line-height: 1.6;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.intro a {
  color: #0073e6;
  text-decoration: none;
}

.intro p {
  font-family: 'Domine', sans-serif;
}

.email {
  font-family: 'ui-monospace', monospace;
  color: var(--email);
  background-color: #f9f9f9;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}

.email:hover {
  color: var(--hover);
}

.section-divider {
  border: 0;
  border-top: 1px solid #e4e2e2;
  margin: 2rem 0;
}

.essays {
  text-align: left;
  font-family: 'Domine', serif;
}

.essays h2 {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.essays ul {
  list-style: none;
  padding: 0;
  font-size: 0.9em;
}

.essays li {
  margin-bottom: 1.5rem;
}

.essays a {
  text-decoration: none;
  color: var(--writings);
}

.essays a:hover {
  color: var(--hover);
  text-decoration: underline;
}

/* Angel */

.investments {
  margin-top: 2rem;
}

.investments h2 {
  font-family: 'Domine', serif;
  font-size: 1.2rem;
  color: #9a031e;
  margin-bottom: 1rem;
}

.investments-table {
  width: 100%;
  margin-top: 1rem;
  border-collapse: collapse;
}

.investments-table th,
.investments-table td {
  text-align: left;
  padding: 0.6rem;
  font-family: 'Domine', serif;
  font-size: 0.8rem;
  border-bottom: 1px solid #e0e0e0;
}

.investments-table th {
  background-color: #f9f9f9;
  color: #333;
  font-weight: bold;
}

.investments-table td a {
  color: #9a031e;
  text-decoration: none;
}

.investments-table td a:hover {
  color: #c21833;
  text-decoration: underline;
}

/* Mobile */
@media (max-width: 600px) {
  .nav-links a {
    font-size: 0.8rem; /* Smaller font size for smaller screens */
  }

  .nav-links {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap; /* Allows links to wrap onto the next line */
    gap: 0.5rem; /* Adjust spacing between links */
    justify-content: left; /* Center aligns the links */
  }
}
